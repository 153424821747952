import {BrowserRouter as Router, Routes, Route, Navigate, Redirect, NavLink, Outlet} from "react-router-dom";
import UserLogin from "./components/login.component";
import './App.css';
import {Component} from "react";
import PositionList from "./components/positionlist.component";
import Map from "./components/map.component";
import Datenschutz from "./components/datenschutz.component";
import axios from "axios";
import {win} from "leaflet/src/core/Browser";
import Impressum from "./components/impressum.component";

export default class App extends Component {
    constructor(props) {
        super(props)

        this.logout = this.logout.bind(this);

        this.state = {
            loggedIn: false,
            error: '',
            dataReady: 0
        }
    }

    componentDidMount() {
        axios.get('https://traccar.taxischulz.de/api/session', {withCredentials: true, validateStatus: false})
            .then((res) => {
                if (res.status === 404) {
                    this.setState({loggedIn: false})
                } else if (res.status === 200) {
                    this.setState({loggedIn: true})
                }
                this.setState({dataReady: 1})
            });
    }

    logout() {
        axios.delete('https://traccar.taxischulz.de/api/session', {withCredentials: true})
            .then((res) => {
                console.log(res.data)
                window.location.reload()
            }).catch((error) => {
            console.log(error)
        });
        this.setState({loggedIn: false})
        return <Navigate to="/login"/>
    }

    Layout() {
        return (
            <div className="WebApp">
                <header className="App-header">
                    <nav>
                        <NavLink to="/list">Liste</NavLink>
                        <NavLink to="/map">Karte</NavLink>
                        <NavLink className="pull-right red-button logout" to="/logout">Abmelden</NavLink>
                    </nav>
                </header>
                <main>
                    <Outlet/>
                </main>
            </div>
        )
    }

    App() {
        return (
            <div className="App">
                <main>
                    <Outlet/>
                </main>
            </div>
        )
    }

    render() {
        if (this.state.dataReady < 1) {
            return (
                <div className="contentWrapper">
                    <div className="message info">
                        Loading
                    </div>
                </div>
            )
        } else if (this.state.loggedIn) {
            return (
                <Routes>
                    <Route element={<this.Layout/>}>
                        <Route path="login" element={<Navigate to="/list"/>}/>
                        <Route index element={<PositionList/>}/>
                        <Route path="list" element={<PositionList/>}/>
                        <Route path="map" element={<Map/>}/>
                        <Route path="*" element={<PositionList/>}/>
                    </Route>
                    <Route path="app" element={<this.App/>}>
                        <Route path="login" element={<Navigate to="/app/list"/>}/>
                        <Route index element={<PositionList/>}/>
                        <Route path="list" element={<PositionList/>}/>
                        <Route path="map" element={<Map/>}/>
                        <Route path="*" element={<PositionList/>}/>
                    </Route>
                    <Route path="logout" element={<this.logout/>}/>
                    <Route path="ds" element={<Datenschutz/>}/>
                    <Route path="imp" element={<Impressum/>}/>
                </Routes>
            );
        } else {
            return (
                <Routes>
                    <Route element={<this.App/>}>
                        <Route index element={<UserLogin/>}/>
                        <Route path="login" element={<UserLogin/>}/>
                        <Route path="list" element={<UserLogin/>}/>
                        <Route path="map" element={<UserLogin/>}/>
                        <Route path="logout" element={<this.logout/>}/>
                    </Route>
                    <Route path="app" element={<this.App/>}>
                        <Route index element={<UserLogin/>}/>
                        <Route path="login" element={<UserLogin/>}/>
                        <Route path="list" element={<UserLogin/>}/>
                        <Route path="map" element={<UserLogin/>}/>
                        <Route path="logout" element={<this.logout/>}/>
                    </Route>
                    <Route path="ds" element={<Datenschutz/>}/>
                    <Route path="imp" element={<Impressum/>}/>
                </Routes>
            );
        }

    }
}
