import {divIcon} from "leaflet";
import {renderToStaticMarkup} from "react-dom/server";
import React from "react";

const redIconMarkup = renderToStaticMarkup(<i className="fa fa-taxi fa-2x map-icon-red"/>);
export const redMarkerIcon = divIcon({
    html: redIconMarkup,
});
const greenIconMarkup = renderToStaticMarkup(<i className="fa fa-taxi fa-2x map-icon-green"/>);
export const greenMarkerIcon = divIcon({
    html: greenIconMarkup,
});
