import {Component} from "react";

export default class Datenschutz extends Component {
    render() {
        return (
            <div className="datenschutz">
                <h1>Datenschutzerklärung</h1>
                <h2 id="m14">Einleitung</h2>
                <p>Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer
                    personenbezogenen Daten
                    (nachfolgend auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken und in welchem Umfang
                    verarbeiten. Die
                    Datenschutzerklärung gilt für alle von uns durchgeführten Verarbeitungen personenbezogener Daten,
                    sowohl im Rahmen
                    der Erbringung unserer Leistungen als auch insbesondere auf unseren Webseiten, in mobilen
                    Applikationen sowie
                    innerhalb externer Onlinepräsenzen, wie z.B. unserer Social-Media-Profile (nachfolgend
                    zusammenfassend bezeichnet
                    als "Onlineangebot“).</p>
                <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
                <p>Stand: 30. September 2022</p>
                <h2>Inhaltsübersicht</h2>
                <ul className="index">
                    <li><a className="index-link" href="#m14">Einleitung</a></li>
                    <li><a className="index-link" href="#m3">Verantwortlicher</a></li>
                    <li><a className="index-link" href="#mOverview">Übersicht der Verarbeitungen</a></li>
                    <li><a className="index-link" href="#m13">Maßgebliche Rechtsgrundlagen</a></li>
                    <li><a className="index-link" href="#m27">Sicherheitsmaßnahmen</a></li>
                    <li><a className="index-link" href="#m134">Einsatz von Cookies</a></li>
                    <li><a className="index-link" href="#m317">Geschäftliche Leistungen</a></li>
                    <li><a className="index-link" href="#m225">Bereitstellung des Onlineangebotes und Webhosting</a>
                    </li>
                </ul>
                <h2 id="m3">Verantwortlicher</h2>
                <p>Marc Schulz-Narres<br/>Rotdornweg 20<br/>53757 Sankt Augustin</p>
                E-Mail-Adresse:
                <p>contact@msn-it.services</p>
                <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
                <p>Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung
                    zusammen und
                    verweist auf die betroffenen Personen.</p>
                <h3>Arten der verarbeiteten Daten</h3>
                <ul>
                    <li>Bestandsdaten.</li>
                    <li>Zahlungsdaten.</li>
                    <li>Kontaktdaten.</li>
                    <li>Vertragsdaten.</li>
                    <li>Nutzungsdaten.</li>
                    <li>Meta-/Kommunikationsdaten.</li>
                </ul>
                <h3>Kategorien betroffener Personen</h3>
                <ul>
                    <li>Interessenten.</li>
                    <li>Nutzer.</li>
                    <li>Geschäfts- und Vertragspartner.</li>
                </ul>
                <h3>Zwecke der Verarbeitung</h3>
                <ul>
                    <li>Erbringung vertraglicher Leistungen und Kundenservice.</li>
                    <li>Kontaktanfragen und Kommunikation.</li>
                    <li>Büro- und Organisationsverfahren.</li>
                    <li>Verwaltung und Beantwortung von Anfragen.</li>
                    <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
                    <li>Informationstechnische Infrastruktur.</li>
                </ul>
                <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
                <p>Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir
                    personenbezogene Daten
                    verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale
                    Datenschutzvorgaben in
                    Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten ferner im Einzelfall speziellere
                    Rechtsgrundlagen
                    maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.</p>
                <ul>
                    <li><strong>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b)
                        DSGVO)</strong> - Die
                        Verarbeitung ist für die Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person
                        ist, oder zur
                        Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der betroffenen Person
                        erfolgen.
                    </li>
                    <li><strong>Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO)</strong> - Die Verarbeitung
                        ist zur
                        Erfüllung einer rechtlichen Verpflichtung erforderlich, der der Verantwortliche unterliegt.
                    </li>
                    <li><strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</strong> - Die Verarbeitung
                        ist zur Wahrung
                        der berechtigten Interessen des Verantwortlichen oder eines Dritten erforderlich, sofern nicht
                        die Interessen
                        oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener
                        Daten erfordern,
                        überwiegen.
                    </li>
                </ul>
                <p>Zusätzlich zu den Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale Regelungen
                    zum Datenschutz
                    in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener
                    Daten bei der
                    Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere Spezialregelungen
                    zum Recht auf
                    Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien
                    personenbezogener
                    Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten
                    Entscheidungsfindung im
                    Einzelfall einschließlich Profiling. Des Weiteren regelt es die Datenverarbeitung für Zwecke des
                    Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick auf die Begründung, Durchführung
                    oder Beendigung
                    von Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten. Ferner können
                    Landesdatenschutzgesetze der
                    einzelnen Bundesländer zur Anwendung gelangen.</p>
                <h2 id="m27">Sicherheitsmaßnahmen</h2>
                <p>Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der
                    Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie
                    der
                    unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und
                    Freiheiten natürlicher
                    Personen geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes
                    Schutzniveau zu
                    gewährleisten.</p>
                <p>Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit
                    von Daten durch
                    Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie betreffenden
                    Zugriffs, der
                    Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir
                    Verfahren
                    eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf
                    die Gefährdung
                    der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei
                    der Entwicklung
                    bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des Datenschutzes,
                    durch
                    Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.</p>
                <p>TLS-Verschlüsselung (https): Um Ihre via unserem Online-Angebot übermittelten Daten zu schützen,
                    nutzen wir eine
                    TLS-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen an dem Präfix https:// in der
                    Adresszeile Ihres
                    Browsers.</p>
                <h2 id="m134">Einsatz von Cookies</h2>
                <p>Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die Informationen auf Endgeräten
                    speichern und
                    Informationen aus den Endgeräten auslesen. Z.B. um den Login-Status in einem Nutzerkonto, einen
                    Warenkorbinhalt in
                    einem E-Shop, die aufgerufenen Inhalte oder verwendete Funktionen eines Onlineangebotes speichern.
                    Cookies können
                    ferner zu unterschiedlichen Zwecken eingesetzt werden, z.B. zu Zwecken der Funktionsfähigkeit,
                    Sicherheit und
                    Komfort von Onlineangeboten sowie der Erstellung von Analysen der Besucherströme. </p>
                <p><strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies im Einklang mit den gesetzlichen
                    Vorschriften ein.
                    Daher holen wir von den Nutzern eine vorhergehende Einwilligung ein, außer wenn diese gesetzlich
                    nicht gefordert
                    ist. Eine Einwilligung ist insbesondere nicht notwendig, wenn das Speichern und das Auslesen der
                    Informationen, also
                    auch von Cookies, unbedingt erforderlich sind, um dem den Nutzern einen von ihnen ausdrücklich
                    gewünschten
                    Telemediendienst (also unser Onlineangebot) zur Verfügung zu stellen. Die widerrufliche Einwilligung
                    wird gegenüber
                    den Nutzern deutlich kommuniziert und enthält die Informationen zu der jeweiligen Cookie-Nutzung.
                </p>
                <p><strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </strong>Auf welcher
                    datenschutzrechtlichen
                    Rechtsgrundlage wir die personenbezogenen Daten der Nutzer mit Hilfe von Cookies verarbeiten, hängt
                    davon ab, ob wir
                    Nutzer um eine Einwilligung bitten. Falls die Nutzer einwilligen, ist die Rechtsgrundlage der
                    Verarbeitung Ihrer
                    Daten die erklärte Einwilligung. Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf
                    Grundlage
                    unserer berechtigten Interessen (z.B. an einem betriebswirtschaftlichen Betrieb unseres
                    Onlineangebotes und
                    Verbesserung seiner Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen der Erfüllung unserer
                    vertraglichen Pflichten
                    erfolgt, wenn der Einsatz von Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu
                    erfüllen. Zu
                    welchen Zwecken die Cookies von uns verarbeitet werden, darüber klären wir im Laufe dieser
                    Datenschutzerklärung oder
                    im Rahmen von unseren Einwilligungs- und Verarbeitungsprozessen auf.</p>
                <p><strong>Speicherdauer: </strong>Im Hinblick auf die Speicherdauer werden die folgenden Arten von
                    Cookies
                    unterschieden:</p>
                <ul>
                    <li><strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong> Temporäre Cookies
                        werden spätestens
                        gelöscht, nachdem ein Nutzer ein Online-Angebot verlassen und sein Endgerät (z.B. Browser oder
                        mobile
                        Applikation) geschlossen hat.
                    </li>
                    <li><strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch nach dem Schließen des
                        Endgerätes
                        gespeichert. So können beispielsweise der Login-Status gespeichert oder bevorzugte Inhalte
                        direkt angezeigt
                        werden, wenn der Nutzer eine Website erneut besucht. Ebenso können die mit Hilfe von Cookies
                        erhobenen Daten der
                        Nutzer zur Reichweitenmessung verwendet werden. Sofern wir Nutzern keine expliziten Angaben zur
                        Art und
                        Speicherdauer von Cookies mitteilen (z. B. im Rahmen der Einholung der Einwilligung), sollten
                        Nutzer davon
                        ausgehen, dass Cookies permanent sind und die Speicherdauer bis zu zwei Jahre betragen kann.
                    </li>
                </ul>
                <p><strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): </strong>Nutzer können die von
                    ihnen abgegebenen
                    Einwilligungen jederzeit widerrufen und zudem einen Widerspruch gegen die Verarbeitung entsprechend
                    den gesetzlichen
                    Vorgaben im Art. 21 DSGVO einlegen. Nutzer können ihren Widerspruch auch über die Einstellungen
                    ihres Browsers
                    erklären, z.B. durch Deaktivierung der Verwendung von Cookies (wobei dadurch auch die Funktionalität
                    unserer
                    Online-Dienste eingeschränkt sein kann). Ein Widerspruch gegen die Verwendung von Cookies zu
                    Online-Marketing-Zwecken kann auch über die Websites <a href="https://optout.aboutads.info"
                                                                            target="_blank">https://optout.aboutads.info</a> und <a
                        href="https://www.youronlinechoices.com/"
                        target="_blank">https://www.youronlinechoices.com/</a> erklärt werden.
                </p>
                <h2 id="m317">Geschäftliche Leistungen</h2>
                <p>Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B. Kunden und Interessenten
                    (zusammenfassend
                    bezeichnet als "Vertragspartner") im Rahmen von vertraglichen und vergleichbaren Rechtsverhältnissen
                    sowie damit
                    verbundenen Maßnahmen und im Rahmen der Kommunikation mit den Vertragspartnern (oder
                    vorvertraglich), z.B., um
                    Anfragen zu beantworten.</p>
                <p>Wir verarbeiten diese Daten, um unsere vertraglichen Verpflichtungen zu erfüllen. Dazu gehören
                    insbesondere die
                    Verpflichtungen zur Erbringung der vereinbarten Leistungen, etwaige Aktualisierungspflichten und
                    Abhilfe bei
                    Gewährleistungs- und sonstigen Leistungsstörungen. Darüber hinaus verarbeiten wir die Daten zur
                    Wahrung unserer
                    Rechte und zum Zwecke der mit diesen Pflichten verbundenen Verwaltungsaufgaben sowie der
                    Unternehmensorganisation.
                    Darüber hinaus verarbeiten wir die Daten auf Grundlage unserer berechtigten Interessen an einer
                    ordnungsgemäßen und
                    betriebswirtschaftlichen Geschäftsführung sowie an Sicherheitsmaßnahmen zum Schutz unserer
                    Vertragspartner und
                    unseres Geschäftsbetriebes vor Missbrauch, Gefährdung ihrer Daten, Geheimnisse, Informationen und
                    Rechte (z.B. zur
                    Beteiligung von Telekommunikations-, Transport- und sonstigen Hilfsdiensten sowie Subunternehmern,
                    Banken, Steuer-
                    und Rechtsberatern, Zahlungsdienstleistern oder Finanzbehörden). Im Rahmen des geltenden Rechts
                    geben wir die Daten
                    von Vertragspartnern nur insoweit an Dritte weiter, als dies für die vorgenannten Zwecke oder zur
                    Erfüllung
                    gesetzlicher Pflichten erforderlich ist. Über weitere Formen der Verarbeitung, z.B. zu
                    Marketingzwecken, werden die
                    Vertragspartner im Rahmen dieser Datenschutzerklärung informiert.</p>
                <p>Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir den Vertragspartnern vor oder
                    im Rahmen der
                    Datenerhebung, z.B. in Onlineformularen, durch besondere Kennzeichnung (z.B. Farben) bzw. Symbole
                    (z.B. Sternchen
                    o.ä.), oder persönlich mit.</p>
                <p>Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten, d.h.,
                    grundsätzlich
                    nach Ablauf von 4 Jahren, es sei denn, dass die Daten in einem Kundenkonto gespeichert werden, z.B.,
                    solange sie aus
                    gesetzlichen Gründen der Archivierung aufbewahrt werden müssen. Die gesetzliche Aufbewahrungsfrist
                    beträgt bei
                    steuerrechtlich relevanten Unterlagen sowie bei Handelsbüchern, Inventaren, Eröffnungsbilanzen,
                    Jahresabschlüssen,
                    die zum Verständnis dieser Unterlagen erforderlichen Arbeitsanweisungen und sonstigen
                    Organisationsunterlagen und
                    Buchungsbelegen zehn Jahre sowie bei empfangenen Handels- und Geschäftsbriefen und Wiedergaben der
                    abgesandten
                    Handels- und Geschäftsbriefe sechs Jahre. Die Frist beginnt mit Ablauf des Kalenderjahres, in dem
                    die letzte
                    Eintragung in das Buch gemacht, das Inventar, die Eröffnungsbilanz, der Jahresabschluss oder der
                    Lagebericht
                    aufgestellt, der Handels- oder Geschäftsbrief empfangen oder abgesandt worden oder der Buchungsbeleg
                    entstanden ist,
                    ferner die Aufzeichnung vorgenommen worden ist oder die sonstigen Unterlagen entstanden sind.</p>
                <p>Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder Plattformen einsetzen, gelten im
                    Verhältnis zwischen
                    den Nutzern und den Anbietern die Geschäftsbedingungen und Datenschutzhinweise der jeweiligen
                    Drittanbieter oder
                    Plattformen. </p>
                <ul className="m-elements">
                    <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Zahlungsdaten
                        (z.B.
                        Bankverbindungen, Rechnungen, Zahlungshistorie); Kontaktdaten (z.B. E-Mail, Telefonnummern);
                        Vertragsdaten (z.B.
                        Vertragsgegenstand, Laufzeit, Kundenkategorie).
                    </li>
                    <li><strong>Betroffene Personen:</strong> Interessenten; Geschäfts- und Vertragspartner.</li>
                    <li><strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Kundenservice;
                        Kontaktanfragen
                        und Kommunikation; Büro- und Organisationsverfahren; Verwaltung und Beantwortung von Anfragen.
                    </li>
                    <li><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1
                        S. 1 lit. b)
                        DSGVO); Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO); Berechtigte Interessen
                        (Art. 6 Abs. 1 S. 1
                        lit. f) DSGVO).
                    </li>
                </ul>
                <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
                <ul className="m-elements">
                    <li><strong>Projekt- und Entwicklungsleistungen: </strong>Wir verarbeiten die Daten unserer Kunden
                        sowie
                        Auftraggeber (nachfolgend einheitlich als "Kunden" bezeichnet), um ihnen die Auswahl, den Erwerb
                        bzw. die
                        Beauftragung der gewählten Leistungen oder Werke sowie verbundener Tätigkeiten als auch deren
                        Bezahlung und
                        Zurverfügungstellung bzw. Ausführung oder Erbringung zu ermöglichen.

                        Die erforderlichen Angaben sind als solche im Rahmen des Auftrags-, Bestell- bzw. vergleichbaren
                        Vertragsschlusses gekennzeichnet und umfassen die zur Leistungserbringung und Abrechnung
                        benötigten Angaben
                        sowie Kontaktinformationen, um etwaige Rücksprachen halten zu können. Soweit wir Zugang zu
                        Informationen der
                        Endkunden, Mitarbeitern oder anderer Personen erhalten, verarbeiten wir diese im Einklang mit
                        den gesetzlichen
                        und vertraglichen Vorgaben; <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
                        vorvertragliche Anfragen
                        (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                    </li>
                    <li><strong>Angebot von Software- und Plattformleistungen: </strong>Wir verarbeiten die Daten
                        unserer Nutzer,
                        angemeldeter und etwaiger Testnutzer (nachfolgend einheitlich als "Nutzer" bezeichnet), um ihnen
                        gegenüber
                        unsere vertraglichen Leistungen erbringen zu können sowie auf Grundlage berechtigter Interessen,
                        um die
                        Sicherheit unseres Angebotes gewährleisten und es weiterentwickeln zu können. Die erforderlichen
                        Angaben sind
                        als solche im Rahmen des Auftrags-, Bestell- bzw. vergleichbaren Vertragsschlusses
                        gekennzeichnet und umfassen
                        die zur Leistungserbringung und Abrechnung benötigten Angaben sowie Kontaktinformationen, um
                        etwaige
                        Rücksprachen halten zu können; <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
                        vorvertragliche Anfragen
                        (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                    </li>
                </ul>
                <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
                <p>Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur Verfügung stellen zu können.
                    Zu diesem Zweck
                    verarbeiten wir die IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen unserer
                    Online-Dienste
                    an den Browser oder das Endgerät der Nutzer zu übermitteln.</p>
                <ul className="m-elements">
                    <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
                        Inhalten,
                        Zugriffszeiten); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
                    </li>
                    <li><strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
                        Onlinediensten).
                    </li>
                    <li><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und
                        Nutzerfreundlichkeit;
                        Informationstechnische Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und
                        technischen
                        Geräten (Computer, Server etc.).).
                    </li>
                    <li><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                    </li>
                </ul>
                <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
                <ul className="m-elements">
                    <li><strong>Bereitstellung Onlineangebot auf eigener/ dedizierter Serverhardware: </strong>Für die
                        Bereitstellung
                        unseres Onlineangebotes nutzen wir von uns betriebene Serverhardware sowie den damit verbundenen
                        Speicherplatz,
                        die Rechenkapazität und die Software; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen
                        (Art. 6 Abs. 1
                        S. 1 lit. f) DSGVO).
                    </li>
                </ul>
                <p className="seal"><a href="https://datenschutz-generator.de/"
                                       title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
                                       target="_blank"
                                       rel="noopener noreferrer nofollow">Erstellt mit kostenlosem
                    Datenschutz-Generator.de von Dr.
                    Thomas Schwenke</a></p>
            </div>
        );
    }
}
