import React from 'react';
import {MapContainer, Marker, Popup, TileLayer, Tooltip, ZoomControl} from 'react-leaflet'
import {greenMarkerIcon, redMarkerIcon} from "./markericons.component";
import Position from "./position.component";

export default class Map extends Position {
    getMarkers() {
        return (
            <React.Fragment>
                {this.state.deviceData && this.state.deviceData.filter(this.hasPositionData, this).map((item, index) =>
                    <Marker
                        key={item}
                        icon={this.getPosition(item).attributes.A_Taxameter === "Frei" ? greenMarkerIcon : redMarkerIcon}
                        position={[this.getPosition(item).latitude, this.getPosition(item).longitude]}>
                        <Tooltip
                            className={this.getPosition(item).attributes.A_Taxameter === "Frei" ? "tooltip-free" : "tooltip-occupied"}
                            direction="bottom"
                            offset={[6, -4]}
                            opacity={1}
                            permanent>
                            {item.name}
                        </Tooltip>
                        {console.log(this.getPosition(item))}
                        <Popup>
                            {this.getPosition(item).address?.replace(", Germany", "")}<br/>
                            Taxameter: {this.getPosition(item).attributes.A_Taxameter}<br/>
                            Zündung: {this.getPosition(item).attributes.A_Zündung}
                        </Popup>
                    </Marker>
                )
                }
            </React.Fragment>
        );
    }

    renderPosition() {
        return (
            <div className={"map-container"}>
                <MapContainer id="map"
                              center={[this.state.positionData[0].latitude, this.state.positionData[0].longitude]}
                              ref={this.mapRef}
                              zoom={13}
                              zoomControl={false}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {this.getMarkers()}
                    <ZoomControl position="bottomright"/>
                </MapContainer>
            </div>
        )
    }
}

