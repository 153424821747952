import React, {Component} from 'react';
import axios from 'axios';

export default class Position extends Component {
    constructor(props) {
        super(props)

        this.getPosition = this.getPosition.bind(this);

        this.state = {
            deviceData: {},
            positionData: {},
            error: "",
            dataReady: 0
        }
    }

    loadData() {
        this.setState({dataReady: 0})
        axios.get('https://traccar.taxischulz.de/api/devices', {withCredentials: true})
            .then((res) => {
                console.log(res.data)
                this.setState({deviceData: res.data})
                this.setState({dataReady: this.state.dataReady + 1})
            }).catch((error) => {
            console.log(error)
            this.setState({error: error})
        });
        axios.get('https://traccar.taxischulz.de/api/positions', {withCredentials: true})
            .then((res) => {
                console.log(res.data)
                this.setState({positionData: res.data})
                this.setState({dataReady: this.state.dataReady + 1})
            }).catch((error) => {
            console.log(error)
            this.setState({error: error})
        });
    }

    componentDidMount() {
        this.loadData()
        this.interval = setInterval(() => this.loadData(), 60000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getPosition(item) {
        if (this.hasPositionData(item)) {
            return this.state.positionData.find(element => element.id === item.positionId)
        } else {
            return {
                address: "-- Keine Daten --",
                attributes: {
                    A_Taxameter: "NoData",
                    A_Zündung: "--"
                }
            }
        }
    }

    hasPositionData(item) {
        return this.state.positionData.some(element => element.id === item.positionId)
    }

    renderPosition() {
        return (
            <div className="contentWrapper">
                <div className="message error">
                    Fehler!
                </div>
            </div>
        )
    }

    render() {
        if (this.state.error) {
            return (
                <div className="contentWrapper">
                    <div className="message error">
                        Login Fehler!
                    </div>
                </div>
            )
        } else {
            if (this.state.dataReady < 2) {
                return (
                    <div className="contentWrapper">
                        <div className="message info">
                            Loading
                        </div>
                    </div>
                )
            } else {
                return this.renderPosition()
            }
        }
    }
}

