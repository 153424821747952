import React from 'react';
import Position from "./position.component";

export default class PositionList extends Position {
    constructor(props) {
        super(props)

        this.TelLink = this.TelLink.bind(this);
    }

    TelLink(props) {
        let className = props.state === "NoData" ? "bg-nodata" : props.state === "Frei" ? "bg-free" : "bg-occupied";
        className += " call-col"
        if (props.phone) {
            return <td className={className}>
                <a href={"tel:" + props.phone}>
                    <div>{props.name} <i className="fa fa-phone"></i></div>
                </a>
            </td>;
        } else {
            return <td className={className + " no-phone"}>{props.name} <i className="fa fa-phone-slash"></i></td>;
        }
    }

    renderPosition() {
        return (
            <div className={"position-table"}>
                <table>
                    <thead>
                    <tr>
                        <th scope="col"><i className="fa fa-taxi"></i></th>
                        <th scope="col"><i className="fa fa-road"></i></th>
                        <th scope="col"><i className="fa fa-key"></i></th>
                    </tr>
                    </thead>
                    <tbody>
                    {!this.state.error && this.state.deviceData && this.state.deviceData.sort((a, b) => parseInt(a.name) - parseInt(b.name)).map((item, index) =>
                        <tr
                            key={index}
                        >
                            <this.TelLink
                                name={item.name}
                                phone={item.phone}
                                state={this.getPosition(item).attributes.A_Taxameter}
                            />
                            <td>
                                {this.getPosition(item).address?.replace(", Germany", "")}
                            </td>
                            <td>
                                {this.getPosition(item).attributes.A_Zündung}
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        )
    }
}

