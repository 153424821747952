import {Component} from "react";

export default class Impressum extends Component {
    render() {
        return (
            <div className="datenschutz">
                <h1>Impressum</h1>

                <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                <p>Marc Schulz-Narres<br />
                    Rotdornweg 20<br />
                    53757 Sankt Augustin</p>

                <h2>Kontakt</h2>
                <p>Telefon: 0 22 41 / 9 55 8 77 5 - 3<br />
                    Telefax: 0 22 41 / 9 55 8 77 5 - 9<br />
                    E-Mail: contact@msn-it.services</p>

                <h2>Umsatzsteuer-ID</h2>
                <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
                    DE355054816</p>

                <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
            </div>
        );
    }
}
