import React, {Component} from 'react';
import axios from 'axios';
import {Navigate} from "react-router-dom";

export default class UserLogin extends Component {
    constructor(props) {
        super(props)

        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeUserEmail = this.onChangeUserEmail.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.renderErrorMessage = this.renderErrorMessage.bind(this);

        this.state = {
            loggedIn: false,
            password: '',
            email: '',
            error: ''
        }
    }

    componentDidMount() {
        axios.get('https://traccar.taxischulz.de/api/session', {withCredentials: true, validateStatus: false})
            .then((res) => {
                if(res.status===404){
                    this.setState({loggedIn: false})
                } else if(res.status===200){
                    this.setState({loggedIn: true})
                }
            });
    }

    onChangePassword(e) {
        this.setState({password: e.target.value})
    }

    onChangeUserEmail(e) {
        this.setState({email: e.target.value})
    }

    onSubmit(e) {
        e.preventDefault()

        const userObject = new URLSearchParams({
            email: this.state.email,
            password: this.state.password
        });

        axios.post('https://traccar.taxischulz.de/api/session', userObject, {withCredentials: true})
            .then((res) => {
                window.location.reload()
                this.setState({loggedIn: true})
            }).catch((error) => {
            this.setState({error: error})
        });

        this.setState({password: '', email: '', error: ''})
    }

    renderErrorMessage() {
        if (this.state.error) {
            return (
                <div className="error">
                    Login Fehler!
                </div>
            )
        } else {
            return ""
        }
    }

    render() {
        if(this.state.loggedIn){
            return <div>Ooops! You should not be here</div>
        } else {
            return (
                <div className="contentWrapper">
                    <div className="login-wrapper">
                        <h1>TaxiTrack Anmeldung</h1>
                        {this.renderErrorMessage()}
                        <form onSubmit={this.onSubmit}>
                            <input type="email" placeholder="E-Mail" value={this.state.email}
                                   onChange={this.onChangeUserEmail}/>
                            <input type="password" placeholder="Passwort" value={this.state.password}
                                   onChange={this.onChangePassword}/>
                            <input className="button" type="submit"/>
                        </form>
                    </div>
                </div>
            )
        }
    }
}

